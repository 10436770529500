import React, { useState, useEffect } from 'react';
import { webSocketService } from '../services/websocketService';

interface TournamentTimeLeftProps {
  tournamentId: string;
  onTournamentStart: () => void;
  startTime: Date;
}

const TournamentTimeLeft: React.FC<TournamentTimeLeftProps> = ({
  tournamentId,
  onTournamentStart,
  startTime,
}) => {
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [tournamentStarted, setTournamentStarted] = useState(false);

  useEffect(() => {
    calculateInitialTime();
    const timer = startLocalTimer();
    const handleWebSocketMessage = (message: any) => {
      if (
        message.type === 'tournament_update' &&
        message.game_id === tournamentId &&
        message.hasOwnProperty('remaining_time')
      ) {
        setRemainingSeconds(message.remaining_time);
        if (message.remaining_time <= 0 && !tournamentStarted) {
          setTournamentStarted(true);
          onTournamentStart();
        }
      }
    };

    webSocketService.addMessageHandler(handleWebSocketMessage);

    return () => {
      clearInterval(timer);
      webSocketService.removeMessageHandler(handleWebSocketMessage);
    };
  }, [tournamentId, onTournamentStart, startTime]);

  const calculateInitialTime = () => {
    const now = new Date();
    if (startTime > now) {
      setRemainingSeconds(Math.floor((startTime.getTime() - now.getTime()) / 1000));
    } else {
      setRemainingSeconds(0);
      setTournamentStarted(true);
      onTournamentStart();
    }
  };

  const startLocalTimer = () => {
    return setInterval(() => {
      setRemainingSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else if (!tournamentStarted) {
          setTournamentStarted(true);
          onTournamentStart();
        }
        return 0;
      });
    }, 1000);
  };

  const formatDuration = (duration: number) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <div>Starts in: {formatDuration(remainingSeconds)}</div>
      {tournamentStarted && <div>Tournament has started</div>}
    </div>
  );
};

export default TournamentTimeLeft;