import { CONFIG } from '../config';
import { getIdToken } from './authService';

interface RazorpaySessionResponse {
  orderId: string;
  amount: number;
}

export const razorpayService = {
  createOrder: async (amount: number): Promise<RazorpaySessionResponse> => {
    const token = await getIdToken();
    const response = await fetch(`${CONFIG.backendUrl}/razorpay/create-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to create Razorpay order. Status:', response.status, 'Response:', errorText);
      throw new Error('Failed to create payment order');
    }

    const data = await response.json();
    return {
      orderId: data.orderId,
      amount: amount,
    };
  },

  verifyPayment: async (
    orderId: string,
    paymentId: string,
    signature: string
  ): Promise<boolean> => {
    const token = await getIdToken();
    const response = await fetch(`${CONFIG.backendUrl}/razorpay/verify-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        razorpay_order_id: orderId,
        razorpay_payment_id: paymentId,
        razorpay_signature: signature,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Payment verification failed. Status:', response.status, 'Response:', errorText);
      throw new Error('Payment verification failed');
    }

    const data = await response.json();
    return data.verified;
  },
};
