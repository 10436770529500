import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: #1a2a50;
  text-align: center;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 30px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const HowToPlay: React.FC = () => {
  return (
    <Container>
      <Title>How to Play Chess Supreme</Title>
      
      <Section>
        <h2>Getting Started</h2>
        <p>Welcome to Chess Supreme! Here's everything you need to know to start playing.</p>
        {/* Add your how to play content here */}
      </Section>

      <Section>
        <h2>Game Modes</h2>
        <p>Chess Supreme offers several exciting ways to play:</p>
        {/* Add game modes content */}
      </Section>

      <Section>
        <h2>Rules and Guidelines</h2>
        <p>Basic rules and guidelines for playing on Chess Supreme:</p>
        {/* Add rules content */}
      </Section>
    </Container>
  );
};

export default HowToPlay;