import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { Card, CardContent, Typography, Button, Snackbar, Chip, LinearProgress, Tooltip, IconButton } from '@mui/material';
import { checkSufficientBalance } from '../services/walletService';
import { joinTournament, Tournament } from '../services/tournamentService';
import { webSocketService } from '../services/websocketService';
import TournamentTimeLeft from './TournamentTimeLeft';
import { useNavigate } from 'react-router-dom';
import { getIdToken, getUserId } from '../services/authService';
import { CONFIG, wsUrl } from '../config';
import axios from 'axios';
import { AccessTime, EmojiEvents, EmojiPeople, Info, Person } from '@mui/icons-material';

const StyledCard = styled.div<{ $isWaiting: boolean; $isDisabled: boolean }>`
  width: 100%;
  margin: 8px 0;
  background: linear-gradient(145deg, #F0F0F0, #0FB5F7);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  cursor: ${props => props.$isDisabled ? 'not-allowed' : 'pointer'};
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: ${props => props.$isWaiting ? 0.7 : 1};
  pointer-events: ${props => props.$isDisabled ? 'none' : 'auto'};

  &:hover {
    transform: ${props => props.$isDisabled ? 'none' : 'translateY(-5px)'};
  }
`;

const CardHeader = styled.div`
  background: linear-gradient(90deg, #1e3c72, #501f66);
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;


const CardMain = styled(CardContent)`
  padding: 16px;
`;

const LeftSection = styled.div`
  flex: 1;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PrizePool = styled(Typography)`
  font-size: 25px;
  font-weight: bold;
  color: #90EE90; // Light green color
`;

const SlotsProgress = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Icon = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: #000000;
`;

const ActionButton = styled(Button)`
  background-color: yellow;
  color: #000000;
  font-weight: bold;
  &:hover {
    background-color: #FFC600;
  }
`;
const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: rgba(256, 256, 256, 1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const FooterItem = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #06402B;
  & > * {
    margin-right: 4px;
  }
`;

const TypeChip = styled(Chip)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

const WaitingMessage = styled(Typography)`
  color: red;
  font-weight: bold;
`;

const RoundStartedMessage = styled(Typography)`
  color: green;
  font-weight: bold;
`;





interface TournamentCardProps {
  tournament: Tournament;
  onJoinSuccess: (tournamentId: string) => void;
}
interface User {
  id: number;
  uid: string;
  username: string;
  mobile_number: string | null;
  email: string | null;
  created_at: string;
  balance: number;
  deposits: number;
  winnings: number;
  kyc_status: string;
  pan_verified: boolean;
  aadhaar_verified: boolean;
  upi_verified: boolean;
}





const TournamentCard: React.FC<TournamentCardProps> = ({ tournament: initialTournament, onJoinSuccess }) => {
  const [isJoining, setIsJoining] = useState(false);
  const [tournamentStarted, setTournamentStarted] = useState(false);
  const [isEliminated, setIsEliminated] = useState(false);
  const [isWaitingForNextRound, setIsWaitingForNextRound] = useState(false);
  const [isTenSecondCountdownActive, setIsTenSecondCountdownActive] = useState(false);
  const [countdownSeconds, setCountdownSeconds] = useState(10);
  const [canPlayLate, setCanPlayLate] = useState(false);
  const [showGameOverDialog, setShowGameOverDialog] = useState(false);
  const [gameOverMessage, setGameOverMessage] = useState('');
  const [winningAmount, setWinningAmount] = useState<number | null>(null);
  const navigate = useNavigate();
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isWaitingForGame, setIsWaitingForGame] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isWaitingForRoundStart, setIsWaitingForRoundStart] = useState(false);
  const [tournament, setTournament] = useState(initialTournament);
  const [currentRound, setCurrentRound] = useState(() => {
    const savedRound = localStorage.getItem(`tournament_${initialTournament.id}_round`);
    return savedRound ? parseInt(savedRound, 10) : 1;
  });
  
  
  const calculateTotalRounds = useCallback((totalSlots: number): number => {
    return Math.log2(totalSlots);
  }, []);

  const getRoundName = useCallback((currentRound: number, totalRounds: number): string => {
    if (currentRound === totalRounds) {
      return "Final";
    } else if (currentRound === totalRounds - 1) {
      return "Semi-Final";
    } else if (currentRound === totalRounds - 2) {
      return "Quarter-Final";
    } else {
      return `${getOrdinalSuffix(currentRound)} Round`;
    }
  }, []);

  const getOrdinalSuffix = (num: number): string => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num + "th";
  };
  
  const updateCurrentRound = useCallback((newRound: number) => {
    setCurrentRound(newRound);
    localStorage.setItem(`tournament_${tournament.id}_round`, newRound.toString());
  }, [tournament.id]);


  const handleTournamentUpdate = useCallback((message: any) => {
    const totalRounds = calculateTotalRounds(tournament.total_slots);
    console.log(`Total rounds: ${totalRounds}`);
    switch (message.status) {
      case 'ended':
        handleTournamentEnded(message);
        break;
      case 'round_completed':
        handleRoundCompleted();
        break;
        case 'started':
        case 'new_round_starting':
            setTournamentStarted(true);
            if (tournament.joined) {
              startTenSecondCountdown(message.countdown || 10);
              
            }
            break;
    
      case 'new_round_started':
        setIsWaitingForRoundStart(false);
        handleNewRoundStarted();
        if(tournament.joined){
          console.log(`New round starting. Current round before update: ${currentRound}`);
          updateCurrentRound(Math.min(currentRound + 1, totalRounds));
        }
        break;
      case 'slots_update':
        handleSlotsUpdate(message.slots_joined);
        break;
    }
  }, []);


  useEffect(() => {
    checkTournamentStatus();
    webSocketService.connectToTournamentUpdates();

    const handleWebSocketMessage = (message: any) => {
      if (message.type === 'tournament_update' && message.game_id === tournament.id) {
        console.log("Processing tournament update for", tournament.id, message);
        handleTournamentUpdate(message);
      }
    };

    webSocketService.addMessageHandler(handleWebSocketMessage);

    return () => {
      webSocketService.removeMessageHandler(handleWebSocketMessage);
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, [tournament.id]);

  const checkTournamentStatus = useCallback(() => {
    const now = new Date();
    const startDate = new Date(tournament.start_date);
    if (startDate <= now) {
      setTournamentStarted(true);
      setCanPlayLate(tournament.joined && !isEliminated);
    }
  }, [tournament.start_date, tournament.joined, isEliminated]);



  const handleTournamentEnded = useCallback((message: any) => {
    setTournamentStarted(false);
    setIsEliminated(true);
    console.log("Tournament ended. Winner:", message.result);
  }, []);

  const handleRoundCompleted = useCallback(() => {
    setIsWaitingForNextRound(true);
    setTournamentStarted(true);
   console.log("Round completed. Waiting for next round.");
  }, []);
  const removeCard = useCallback(() => {
    console.log("Removing tournament card");
    setIsEliminated(true);
  }, []);


  const handleTournamentStarted = useCallback(() => {
    console.log("Tournament started event triggered");
    setTournamentStarted(true);
    setCanPlayLate(tournament.joined && !isEliminated);
   console.log("Tournament started. Can play late:", canPlayLate);
    if (tournament.joined) {
      console.log("User has joined the tournament. Waiting for round to start.");
      setIsWaitingForGame(true);
    } else {
      console.log("User has not joined the tournament. Removing card.");
      removeCard();
    }
  }, [tournament.joined, isEliminated, canPlayLate, removeCard]);

  const handleNewRoundStarting = useCallback((countdown: number) => {
    setIsWaitingForNextRound(false);
    setIsWaitingForGame(true);
    startTenSecondCountdown(countdown);
   console.log("New round starting in", countdown, "seconds.");
  }, []);

  const handleNewRoundStarted = useCallback(() => {
    setIsTenSecondCountdownActive(false);
    setIsWaitingForNextRound(false);
    setIsWaitingForGame(true);
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    startGame();
  }, []);

  const handleSlotsUpdate = useCallback((slotsJoined: number) => {
    setTournament(prevTournament => ({
      ...prevTournament,
      slots_joined: slotsJoined
    }));
   console.log("Slots updated:", slotsJoined);
  }, []);


  const handleEnterTournament = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/tournament/${tournament.id}`, { state: { tournament } });
  }, [navigate, tournament]);


  const handleCardClick = useCallback((event: React.MouseEvent) => {
    if (!isWaitingForRoundStart) {
      navigate(`/tournament/${tournament.id}`, { state: { tournament } });
    }
  }, [isWaitingForRoundStart, navigate, tournament]);

  const startTenSecondCountdown = useCallback((seconds = 10) => {
    setIsWaitingForRoundStart(true);
    setCountdownSeconds(seconds);

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    countdownIntervalRef.current = setInterval(() => {
      setCountdownSeconds((prevSeconds) => {
        if (prevSeconds > 1) {
          return prevSeconds - 1;
        } else {
          if (countdownIntervalRef.current) {
            clearInterval(countdownIntervalRef.current);
          }
          setIsWaitingForRoundStart(false);
          setIsTenSecondCountdownActive(false);
         console.log("Countdown finished. Starting game.");
          startGame();
          return 0;
        }
      });
    }, 1000);
  }, []);



  const fetchPairingInfo = useCallback(async (tournamentId: string, userId: string): Promise<any[]> => {
    try {
      const token = await getIdToken();
      const url = `${CONFIG.backendUrl}/tournaments/${tournamentId}/users/${userId}/pairing`;
     console.log("Fetching pairing info from URL:", url);
     console.log("Using user ID:", userId);

      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      console.log("Pairing info - HTTP status code:", response.status);
     console.log("Pairing info - Response data:", JSON.stringify(response.data, null, 2));

      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          console.error("Unexpected pairing info format:", response.data);
          throw new Error('Unexpected response format');
        }
      } else {
        console.error(`Failed to fetch pairing info. Status: ${response.status}, Data:`, response.data);
        throw new Error(`Failed to fetch pairing info: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching pairing info:", error);
      throw error;
    }
  }, []);

  const startGame = useCallback(async () => {
    try {
      const actualUserId = await getUserId();
      const pairings = await fetchPairingInfo(tournament.id, actualUserId);
  
      if (pairings.length === 0) {
        navigate(`/waiting-for-game`, {
          state: {
            tournamentId: tournament.id,
            userId: actualUserId,
          },
        });
        return;
      }
  
      const userPairing = pairings.find(
        (pairing: any) => pairing.white_player === actualUserId || pairing.black_player === actualUserId
      );
  
      if (!userPairing) {
        throw new Error('No valid pairing information found for user');
      }
  
      // Get fresh token right before creating WebSocket URL
      const freshToken = await getIdToken();
      const playerColor = userPairing.white_player === actualUserId ? 'w' : 'b';
      
      // Properly construct WebSocket URL accounting for both http and https
      const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      const baseUrl = CONFIG.backendUrl.replace(/^https?:\/\//, '');
     // const wsUrl = `${protocol}${baseUrl}/ws?game_id=${userPairing.game_id}&token=${freshToken}&type=tournament_game`;
  
      // Establish WebSocket connection before navigation
      try {
        await webSocketService.connect('game', userPairing.game_id, wsUrl);
        console.log('Tournament game WebSocket connected successfully');
  
        // Send initial state request
        webSocketService.sendMessage({
          status: 'get_current_state',
          game_id: userPairing.game_id
        });
  
        // Navigate only after successful connection
        navigate(`/game/${userPairing.game_id}`, {
          state: {
            playerColor,
            wsUrl,
            tournamentId: tournament.id,
            //gameType: 'tournament_game'  // Add explicit game type
          }
        });
      } catch (error) {
        console.error('Failed to establish WebSocket connection:', error);
        throw new Error('Failed to connect to game server');
      }
    } catch (error) {
      console.error("Failed to start game:", error);
      setSnackbarMessage("Error starting the game. Please try again.");
      setSnackbarOpen(true);
    }
  }, [tournament.id, navigate, fetchPairingInfo]);

  useEffect(() => {
    const handleGameComplete = (event: CustomEvent) => {
      const { userWon, amount } = event.detail;
      if (userWon) {
        setIsWaitingForNextRound(true);
        setGameOverMessage("Congratulations! You won this round. Wait for the next round to begin.");
      } else {
        setIsEliminated(true);
        setGameOverMessage(`You've been eliminated from the tournament.${amount ? ` You've won ₹${amount.toFixed(2)}!` : ''}`);
      }
      setWinningAmount(amount);
      setShowGameOverDialog(true);
    };

    window.addEventListener('gameComplete', handleGameComplete as EventListener);

    return () => {
      window.removeEventListener('gameComplete', handleGameComplete as EventListener);
    };
  }, []);

  const handleGameOverDialogClose = useCallback(() => {
    setShowGameOverDialog(false);
    if (isEliminated) {
      navigate('/home'); // Navigate to home screen
    }
  }, [isEliminated, navigate]);

  const getTypeColor = (type: string) => {
    switch (type.toLowerCase()) {
      case 'elimination':
        return 'error';
      default:
        return 'default';
    }
  };

  useEffect(() => {
    setTournament(initialTournament);
  }, [initialTournament]);



  const handleJoinTournament = useCallback(async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (tournament.joined || isJoining) return;
    setIsJoining(true);

    try {
      const hasBalance = await checkSufficientBalance(tournament.entry_fee);
      if (!hasBalance) {
        setSnackbarMessage('Insufficient balance. Please add money to your wallet.');
        setSnackbarOpen(true);
        setIsJoining(false);
        return;
      }

      const confirmed = window.confirm(`Are you sure you want to join this tournament? ₹${tournament.entry_fee.toFixed(2)} will be deducted from your wallet.`);
      if (!confirmed) {
        setIsJoining(false);
        return;
      }

      await joinTournament(tournament.id);
      setSnackbarMessage('Successfully joined the tournament!');
      setSnackbarOpen(true);
      onJoinSuccess(tournament.id);

      // Set a flag in localStorage to indicate successful join
      localStorage.setItem('tournamentJoined', 'true');

      // Reload the page
      window.location.reload();
    } catch (error) {
      console.error('Error joining tournament:', error);
      setSnackbarMessage('An error occurred while joining the tournament.');
      setSnackbarOpen(true);
    }

    setIsJoining(false);
  }, [tournament, isJoining, onJoinSuccess]);
 

  const calculatePercentWinners = (): number => {
    return 50; // For elimination tournaments, always 50%
  };

  const calculateFirstPrize = (): number => {
    return tournament.entry_fee * (tournament.winnings.winner ?? 0);
  };

  const progress = (tournament.slots_joined / tournament.total_slots) * 100;

 
  const renderActionButton = useCallback(() => {
    if (isEliminated || (tournamentStarted && !tournament.joined)) {
      return null;
    }
    if (isEliminated) {
      return <Typography color="error">Eliminated</Typography>;
    } else if (isWaitingForGame || isWaitingForRoundStart) {
      return <ActionButton >ENTER</ActionButton>;
    } else if (tournament.joined) {
      return (
        <ActionButton
          onClick={handleEnterTournament}
          disabled={isWaitingForRoundStart}
        >
          ENTER
        </ActionButton>
      );
    } else {
      return (
        <ActionButton
          onClick={handleJoinTournament}
          disabled={isJoining || isWaitingForRoundStart}
        >
          {isJoining ? 'Joining...' : `₹${tournament.entry_fee.toFixed(2)}`}
        </ActionButton>
      );
    }
  }, [isEliminated, isWaitingForGame, isWaitingForRoundStart, tournament.joined, handleEnterTournament, handleJoinTournament, isJoining, tournament.entry_fee]);

  if (isEliminated) {
    return null;
  }
  const renderTimeInfo = () => {
    const totalRounds = calculateTotalRounds(tournament.total_slots);
    const roundName = currentRound > 0 ? getRoundName(currentRound, totalRounds) : 'Tournament';

    if (!tournamentStarted) {
      return (
        <TournamentTimeLeft
          tournamentId={tournament.id}
          onTournamentStart={handleTournamentStarted}
          startTime={new Date(tournament.start_date)}
        />
      );
    }

    if (isWaitingForRoundStart) {
      return <Typography variant="body2" style={{ color: 'orange' }}>{roundName} starting soon...</Typography>;
    } else {
      return <WaitingMessage variant="body2">{roundName}</WaitingMessage>;
    }
  };


  return (
    <StyledCard 
      $isWaiting={isWaitingForRoundStart} 
      $isDisabled={isWaitingForRoundStart}
      onClick={handleCardClick}
    >
      <CardHeader>
        <PrizePool variant="h5">Prize pool: ₹{tournament.prize_pool.toFixed(2)}</PrizePool>
      </CardHeader>
      <CardMain>
        <InfoRow>
          <Icon><Person /></Icon>
          <Typography variant="body2">
            Slots: {tournament.slots_joined}/{tournament.total_slots}
          </Typography>
        </InfoRow>
        <LinearProgress 
          variant="determinate" 
          value={(tournament.slots_joined / tournament.total_slots) * 100} 
          sx={{
            marginBottom: '16px',
            backgroundColor: '#E0E0E0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#1e3c72'
            }
          }}
        />
        <InfoRow>
          <Icon><AccessTime /></Icon>
          {renderTimeInfo()}
        </InfoRow>
        {renderActionButton()}
      </CardMain>
      <CardFooter>
        <FooterItem>
          <EmojiEvents fontSize="small" style={{ color: '#FFD700' }} />
          1st: ₹{calculateFirstPrize().toFixed(2)}
        </FooterItem>
        <FooterItem>
          <EmojiPeople fontSize="small" style={{ color: '#FFD700' }} />
          Winners: {calculatePercentWinners()}%
        </FooterItem>
      </CardFooter>
     
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </StyledCard>
  );
};

export default TournamentCard;