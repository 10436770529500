import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Card, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Person } from '@mui/icons-material';
import { checkSufficientBalance } from '../services/walletService';
import { useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(145deg, #F0F0F0, #0FB5F7);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const CardHeader = styled.div`
  background: linear-gradient(90deg, #1a2a50 0%, #501f66 100%);
  color: white;
  padding: 16px;
  border-radius: 15px 15px 0 0;
  text-align: center;
`;

const CardTitle = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const AmountWrapper = styled.div`
  text-align: center;
`;

const AmountLabel = styled(Typography)`
  color: black;
  font-size: 1rem;
  margin-bottom: 4px;
`;

const AmountValue = styled(Typography)`
  font-weight: 1000;
  font-size: 1.2rem;
  color: green;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionButton = styled(Button)`
  background: yellow;
  color: black;
  font-weight: bold;
  &:hover {
    background: linear-gradient(90deg, red 0%, red 100%);
  }
`;

const PlayerCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 15px 15px;
`;

interface CashGameCardProps {
  cashGame: {
    id: string;
    amount: number;
    playersCount: number;
    winning: number;
  };
}

const CashGameCard: React.FC<CashGameCardProps> = ({ cashGame }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleJoin = async () => {
    setIsLoading(true);
    try {
      const hasBalance = await checkSufficientBalance(cashGame.amount);
      if (hasBalance) {
        setOpenDialog(true);
      } else {
        alert('Insufficient balance. Please add money to your wallet.');
      }
    } catch (error) {
      console.error('Error checking balance:', error);
      alert('An error occurred. Please try again.');
    }
    setIsLoading(false);
  };

  const confirmJoin = () => {
    setOpenDialog(false);
    navigate('/matchmaking', { state: { gameAmount: cashGame.amount } });
  };

  return (
    <StyledCard>
      <CardHeader>
        <CardTitle variant="h6">Cash Challenge</CardTitle>
      </CardHeader>
      <ContentWrapper>
        <AmountWrapper>
          <AmountLabel variant="body1">Win</AmountLabel>
          <AmountValue variant="h6">₹{cashGame.winning}</AmountValue>
        </AmountWrapper>
        <ActionWrapper>
          <AmountLabel variant="body1">Entry</AmountLabel>
          <ActionButton variant="contained" onClick={handleJoin} disabled={isLoading}>
            {isLoading ? 'Loading...' : `₹${cashGame.amount}`}
          </ActionButton>
        </ActionWrapper>
      </ContentWrapper>
      <PlayerCount>
        <Person fontSize="small" style={{ marginRight: '4px' }} />
        <Typography variant="body2">{cashGame.playersCount} Players</Typography>
      </PlayerCount>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Join</DialogTitle>
        <DialogContent>
          Are you sure you want to join this game? ₹{cashGame.amount} will be deducted from your wallet.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmJoin} color="primary">Join</Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
};

export default CashGameCard;