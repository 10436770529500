import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, styled } from '@mui/material';
import ChessBackground from './ChessBackground';
import LoginAppBar from './LoginAppBar';
import ReactMarkdown from 'react-markdown';

const PageContainer = styled(Container)`
  padding-top: 64px;
  padding-bottom: 64px;
`;

const ContentPaper = styled(Paper)`
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.9);
`;

interface InfoPagesProps {
  type: 'terms' | 'privacy' | 'about' | 'contact' | 'refund' | 'download' | 'how-to-play';
}
const InfoPages: React.FC<InfoPagesProps> = ({ type }) => {
  const [content, setContent] = useState<string>('');

  const getPageTitle = (pageType: string): string => {
    switch (pageType) {
      case 'terms':
        return 'Terms and Conditions';
      case 'privacy':
        return 'Privacy Policy';
      case 'about':
        return 'About Us';
      case 'contact':
        return 'Contact Us';
      case 'refund':
        return 'Refund Policy';
      case 'download':
        return 'Download App';
      case 'how-to-play':
        return 'How to Play';
      default:
        return 'Information';
    }
  };

  useEffect(() => {
    const loadMarkdown = async (file: string) => {
      try {
        const response = await fetch(file);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Error fetching markdown file:', error);
      }
    };

    switch (type) {
      case 'terms':
        loadMarkdown('/Info/tnc.md');
        break;
      case 'privacy':
        loadMarkdown('/Info/PrivacyPolicy.md');
        break;
      case 'about':
        loadMarkdown('/Info/AboutUs.md');
        break;
      case 'contact':
        loadMarkdown('/Info/ContactUs.md');
        break;
      case 'refund':
        loadMarkdown('/Info/RefundPolicy.md');
        break;
      case 'download':
        loadMarkdown('/Info/Download.md');
        break;
      case 'how-to-play':
        loadMarkdown('/Info/HowToPlay.md');
        break;
      default:
        setContent('Invalid page type');
    }
  }, [type]);


  return (
    <>
      <LoginAppBar />
      <ChessBackground />
      <PageContainer>
        <ContentPaper>
          <Typography variant="h4" gutterBottom>
            {getPageTitle(type)}
          </Typography>
          <ReactMarkdown>{content}</ReactMarkdown>
        </ContentPaper>
      </PageContainer>
    </>
  );
};

export default InfoPages;