import { CONFIG } from '../config';
import { getIdToken } from './authService';

interface TournamentWinnings {
  round1?: number;
  round2?: number;
  round3?: number;
  round4?: number;
  round5?: number;
  round6?: number;
  round7?: number;
  round8?: number;
  round9?: number;
  round10?: number;
  round11?: number;
  round12?: number;
  round13?: number;
  round14?: number;
  round15?: number;
  round16?: number;
  round17?: number;
  round18?: number;
  round19?: number;
  round20?: number;
  winner?: number;
}

export interface Tournament {
  id: string;
  name: string;
  start_date: string;
  slots_joined: number;
  total_slots: number;
  status: string;
  entry_fee: number;
  prize_pool: number;
  winner_prize: number;
  type: string;
  joined: boolean;
  winnings: TournamentWinnings;
  current_round: number;
  time_left: number;
}

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const errorText = await response.text();
    console.error("Error response:", response.status, errorText);
    throw new Error(`API error: ${response.status} ${errorText}`);
  }
  return response.json();
};

export const fetchTournaments = async (): Promise<Tournament[]> => {
  try {
    const token = await getIdToken();
   // console.log("Fetching tournaments with token:", token);
    const response = await fetch(`${CONFIG.backendUrl}/tournaments`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await handleResponse(response);
   // console.log("Tournaments data:", data);
    return data || [];
  } catch (error) {
    console.error("Error in fetchTournaments:", error);
    throw error;
  }
};

export const fetchTournamentById = async (id: string): Promise<Tournament> => {
  try {
    const token = await getIdToken();
   // console.log(`Fetching tournament with ID: ${id}`);
    const url = `${CONFIG.backendUrl}/tournaments/${id}`;
    //console.log(`Request URL: ${url}`);
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await handleResponse(response);
   // console.log("Tournament data:", data);
    return data;
  } catch (error) {
    console.error(`Error in fetchTournamentById:`, error);
    throw error;
  }
};
export const joinTournament = async (tournamentId: string): Promise<boolean> => {
  try {
    const token = await getIdToken();
    const response = await fetch(`${CONFIG.backendUrl}/tournaments/join`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ tournament_id: tournamentId }),
    });
    await handleResponse(response);
    return true;
  } catch (error) {
    console.error("Error in joinTournament:", error);
    throw error;
  }
};

export const fetchPairingInfo = async (tournamentId: string, userId: string): Promise<any> => {
  try {
    const token = await getIdToken();
    const response = await fetch(`${CONFIG.backendUrl}/tournaments/${tournamentId}/users/${userId}/pairing`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("Error in fetchPairingInfo:", error);
    throw error;
  }
};
export const fetchTournamentDetails = async (id: string): Promise<Tournament> => {
  try {
    const token = await getIdToken();
    //console.log(`Fetching tournament details with ID: ${id}`);
    const url = `${CONFIG.backendUrl}/tournaments/${id}`;
    //console.log(`Request URL: ${url}`);
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await handleResponse(response);
    //console.log("Tournament details:", data);
    return data;
  } catch (error) {
    console.error(`Error in fetchTournamentDetails:`, error);
    throw error;
  }
};