// App.tsx
import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './firebaseConfig';
import ErrorBoundary from './ErrorBoundary';
import LoadingSpinner from './LoadingSpinner';
import InfoPages from './components/InfoPages';
import CashGameList from './components/CashGameList';
import TournamentList from './components/TournamentList';
import HowToPlay from './components/HowToPlay';
import HelpAndSupport from './components/HelpAndSupport';
import { ToastContainer } from 'react-toastify';

// Lazy load components
const LoginScreen = React.lazy(() => import('./components/LoginScreen'));
const HomeScreen = React.lazy(() => import('./components/HomeScreen'));
const ChessGame = React.lazy(() => import('./components/ChessGame'));
const MatchmakingScreen = React.lazy(() => import('./components/MatchMakingScreen'));
const LogoutPage = React.lazy(() => import('./components/LogOutPage'));
const WalletPage = React.lazy(() => import('./components/WalletPage'));
const WaitingForGame = React.lazy(() => import('./components/WaitingForGame'));
const WaitingForNextRound = React.lazy(() => import('./components/WaitingForNextRound'));
const TournamentDetails = React.lazy(() => import('./components/TournamentDetails'));
const KYCSuccess = React.lazy(() => import('./components/KYCSuccess'));
const KYCFailure = React.lazy(() => import('./components/KYCFailure'));

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const cleanupAppState = () => {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    };

    cleanupAppState();

    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  if (isLoggedIn === null) {
    return <LoadingSpinner />;
  }

  return (
    <ErrorBoundary>
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={isLoggedIn ? <Navigate to="/home/cash-games" /> : <LoginScreen />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/home/*" element={isLoggedIn ? <HomeScreen /> : <Navigate to="/" />}>
              <Route path="cash-games" element={<CashGameList />} />
              <Route path="tournaments" element={<TournamentList />} />
            </Route>
            <Route path="/game/:gameId" element={isLoggedIn ? <ChessGame /> : <Navigate to="/" />} />
            <Route path="/matchmaking" element={isLoggedIn ? <MatchmakingScreen /> : <Navigate to="/" />} />
            <Route path="/waiting-for-game" element={isLoggedIn ? <WaitingForGame /> : <Navigate to="/" />} />
            <Route path="/waiting-for-next-round" element={isLoggedIn ? <WaitingForNextRound /> : <Navigate to="/" />} />
            <Route path="/wallet" element={isLoggedIn ? <WalletPage /> : <Navigate to="/" />} />
            <Route path="/tournament/:id" element={isLoggedIn ? <TournamentDetails /> : <Navigate to="/" />} />
            <Route path="/terms" element={<InfoPages type="terms" />} />
            <Route path="/privacy" element={<InfoPages type="privacy" />} />
            <Route path="/download" element={<InfoPages type="download" />} />
            <Route path="/how-to-play" element={<InfoPages type="how-to-play" />} />
            <Route path="/about" element={<InfoPages type="about" />} />
            <Route path="/contact" element={<InfoPages type="contact" />} />
            <Route path="/refund-policy" element={<InfoPages type="refund" />} />
            <Route path="/how-to-play" element={isLoggedIn ? <HowToPlay /> : <Navigate to="/" />} />
            <Route path="/kyc/success" element={isLoggedIn ? <KYCSuccess /> : <Navigate to="/" />} />
            <Route path="/kyc/failure" element={isLoggedIn ? <KYCFailure /> : <Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
};

export default App;