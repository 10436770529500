import { CONFIG } from '../config';
import { getIdToken } from './authService';

export interface CashGame {
  id: string;
  amount: number;
  playersCount: number;
  winning: number;
}

export interface MatchmakingResult {
  success: boolean;
  error?: string;
  message?: string;
  opponentID?: string;
  gameID?: string;
  playerColor?: string;
}

export interface MatchFoundData {
  gameId: string;
  playerColor: string;
}


export const fetchCashGames = async (): Promise<CashGame[]> => {
  try {
    const token = await getIdToken();
  //  console.log("Fetching cash games with token:", token);
    //console.log("Sending request to:", `${CONFIG.backendUrl}/cash_games`);
    const response = await fetch(`${CONFIG.backendUrl}/cash_games`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

  //  console.log("Received response:", response.status, response.statusText);

    const responseText = await response.text();
   // console.log("Response text:", responseText);

    if (!response.ok) {
      console.error("Error response:", response.status, responseText);
      throw new Error(`Failed to load cash games: ${response.status} ${responseText}`);
    }

    try {
      const data = JSON.parse(responseText);
     // console.log("Cash games data:", data);
      return data.cash_games || [];
    } catch (parseError) {
      console.error("Error parsing JSON:", parseError);
      throw new Error(`Failed to parse response: ${responseText}`);
    }
  } catch (error) {
    console.error("Error in fetchCashGames:", error);
    throw error;
  }
};

export const handleMatchmaking = async (userId: string, gameAmount: number): Promise<MatchmakingResult> => {
  const token = await getIdToken();
  try {
    const response = await fetch(`${CONFIG.backendUrl}/cash-game/matchmaking`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        game: { amount: gameAmount },
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      if (response.status === 403) {
        return {
          success: false,
          error: 'TOURNAMENT_CONFLICT',
          message: data.message || 'You have an upcoming tournament. Please play later.',
        };
      }
      throw new Error(data.message || 'Failed to start matchmaking');
    }

    return {
      success: true,
      opponentID: data.opponentID,
      gameID: data.gameID,
      playerColor: data.playerColor,
    };
  } catch (error) {
    console.error('Error in handleMatchmaking:', error);
    return {
      success: false,
      error: 'UNKNOWN_ERROR',
      message: error instanceof Error ? error.message : 'An unknown error occurred',
    };
  }
};

export const checkMatchStatus = async (userId: string): Promise<any> => {
  const token = await getIdToken();
  const response = await fetch(`${CONFIG.backendUrl}/cash-game/match-status/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to check match status');
  }

  return await response.json();
};

export const exitMatchmaking = async (userId: string, gameAmount: number): Promise<void> => {
  const token = await getIdToken();
  const response = await fetch(`${CONFIG.backendUrl}/cash-game/exit-matchmaking`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: userId,
      game_amount: gameAmount,
    }),
  });

  const data = await response.json();

  // Check for match found case (409 Conflict)
  if (response.status === 409 && data.error === 'MATCH_FOUND') {
    throw {
      type: 'MATCH_FOUND',
      gameId: data.gameId,
      playerColor: data.playerColor
    };
  }

  if (!response.ok) {
    throw new Error('Failed to exit matchmaking');
  }
};