import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import TournamentCard from './TournamentCard';
import { fetchTournaments, Tournament } from '../services/tournamentService';
import { Snackbar, Alert } from '@mui/material';

const GridContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
`;

const TournamentList: React.FC = () => {
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [showJoinSuccess, setShowJoinSuccess] = useState(false);

  const loadTournaments = useCallback(async () => {
    try {
      const tourneys = await fetchTournaments();
      const sortedTourneys = tourneys.sort((a, b) => {
        const dateA = new Date(a.start_date).getTime();
        const dateB = new Date(b.start_date).getTime();
        return dateA - dateB;
      });
      setTournaments(sortedTourneys);
    } catch (error) {
      console.error("Error fetching tournaments:", error);
    }
  }, []);

  useEffect(() => {
    loadTournaments();

    // Check if a tournament was just joined
    const tournamentJoined = localStorage.getItem('tournamentJoined');
    if (tournamentJoined === 'true') {
      setShowJoinSuccess(true);
      localStorage.removeItem('tournamentJoined');
    }
  }, [loadTournaments]);

  const handleJoinSuccess = useCallback((joinedTournamentId: string) => {
    // This function won't be called immediately due to page reload,
    // but we'll keep it for consistency
    //console.log(`Tournament joined successfully: ${joinedTournamentId}`);
  }, []);

  return (
    <>
      <GridContainer>
        {tournaments.map((tournament) => (
          <TournamentCard
            key={tournament.id}
            tournament={tournament}
            onJoinSuccess={handleJoinSuccess}
          />
        ))}
      </GridContainer>
      <Snackbar 
        open={showJoinSuccess} 
        autoHideDuration={6000} 
        onClose={() => setShowJoinSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowJoinSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Successfully joined the tournament!
        </Alert>
      </Snackbar>
    </>
  );
};

export default TournamentList;