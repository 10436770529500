import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBbkQ2fowf9L_K8XpZy1V-XuFHIDsGDQ8A",
    authDomain: "chessclash-4b409.firebaseapp.com",
    databaseURL: "https://chessclash-4b409-default-rtdb.firebaseio.com",
    projectId: "chessclash-4b409",
    storageBucket: "chessclash-4b409.appspot.com",
    messagingSenderId: "326191140329",
    appId: "1:326191140329:web:d87b5fe4e3184bc0e30f78",
    measurementId: "G-B45L74VR6T"
  };
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

