export const CONFIG = {
  backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://api.chesssupreme.com',
  wsProtocol: window.location.protocol === 'https:' ? 'wss:' : 'ws:',
};

export const wsUrl = CONFIG.backendUrl.replace(/^https:/, CONFIG.wsProtocol);

export const getWsUrl = (path: string) => {
  const wsUrl = CONFIG.backendUrl.replace(/^https:/, CONFIG.wsProtocol);
  return `${wsUrl}${path}`;
};

//console.log("Backend URL:", CONFIG.backendUrl);