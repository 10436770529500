import { getIdToken } from './authService';
import { CONFIG } from '../config';

type WebSocketMessageHandler = (message: any) => void;
type ConnectionType = 'tournament_updates' | 'game';

class WebSocketService {
    // Socket instances for different connection types
    private gameSocket: WebSocket | null = null;
    private tournamentSocket: WebSocket | null = null;

    // Message handlers and configuration
    private messageHandlers: WebSocketMessageHandler[] = [];
    private reconnectAttempts: number = 0;
    private maxReconnectAttempts: number = 120;
    private reconnectInterval: number = 3000;
    private gameId: string | null = null;
    private connectionType: ConnectionType | null = null;
    private isConnecting: { [key in ConnectionType]?: boolean } = {};

    async connect(type: ConnectionType, gameId?: string, fullUrl?: string): Promise<void> {
        console.log(`Attempting to connect ${type}. Game socket exists: ${!!this.gameSocket}, Tournament socket exists: ${!!this.tournamentSocket}`);
        
        // Check if already connecting this type
        if (this.isConnecting[type]) {
            console.log(`Connection for ${type} already in progress`);
            return;
        }
       

        this.isConnecting[type] = true;
        this.connectionType = type;
        this.gameId = type === 'game' ? gameId || null : null;

        try {
            const token = await getIdToken();
            let wsUrl: string;

            if (fullUrl) {
                wsUrl = fullUrl;
            } else if (type === 'game' && this.gameId) {
                wsUrl = `${CONFIG.backendUrl.replace(/^http/, 'ws')}/ws?game_id=${this.gameId}&token=${token}`;
            } else if (type === 'tournament_updates' && gameId) {
                wsUrl = `${CONFIG.backendUrl.replace(/^http/, 'ws')}/ws?type=tournament_updates&game_id=${gameId}&token=${token}`;
            } else if (type === 'tournament_updates') {
                wsUrl = `${CONFIG.backendUrl.replace(/^http/, 'ws')}/ws?type=tournament_updates&token=${token}`;
            } else {
                throw new Error('Invalid connection type or missing game ID');
            }

            console.log(`Attempting to connect to WebSocket (${type}):`, wsUrl);
            const socket = new WebSocket(wsUrl);

            socket.onopen = () => this.onConnect(type, socket);
            socket.onmessage = (event) => this.onMessage(event, type);
            socket.onclose = (event) => this.onDisconnect(event, type);
            socket.onerror = (event) => this.onError(event, type);

            if (type === 'game') {
                this.gameSocket = socket;
            } else {
                this.tournamentSocket = socket;
            }
        } catch (error) {
            console.error(`Error connecting to WebSocket (${type}):`, error);
            this.isConnecting[type] = false;
            this.scheduleReconnect(type);
        }
    }
   
    connectToTournamentUpdates(gameId?: string): void {
        this.connect('tournament_updates', gameId);
    }

    private onConnect = (type: ConnectionType, socket: WebSocket): void => {
        console.log(`WebSocket (${type}) connected successfully`);
        this.isConnecting[type] = false;
        this.reconnectAttempts = 0;
    };

    private onMessage = (event: MessageEvent, type: ConnectionType): void => {
        console.log(`Received WebSocket message (${type}):`, event.data);
        try {
            const message = JSON.parse(event.data);
            this.messageHandlers.forEach((handler) => handler(message));
        } catch (error) {
            console.error(`Error parsing WebSocket message (${type}):`, error);
        }
    };

    private onDisconnect = (event: CloseEvent, type: ConnectionType): void => {
        console.log(`WebSocket (${type}) disconnected. Code: ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
        this.isConnecting[type] = false;
        
        if (type === 'game') {
            this.gameSocket = null;
        } else {
            this.tournamentSocket = null;
        }

        // Only attempt to reconnect if it wasn't a normal closure
        if (event.code !== 1000) {
            this.scheduleReconnect(type);
        }
    };

    private onError = (event: Event, type: ConnectionType): void => {
        console.error(`WebSocket (${type}) error:`, event);
        this.isConnecting[type] = false;
        
        const socket = type === 'game' ? this.gameSocket : this.tournamentSocket;
        if (socket && socket.readyState !== WebSocket.CLOSED) {
            socket.close();
        }
        
        if (type === 'game') {
            this.gameSocket = null;
        } else {
            this.tournamentSocket = null;
        }

        this.scheduleReconnect(type);
    };

    private scheduleReconnect(type: ConnectionType): void {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++;
            console.log(`Attempting to reconnect ${type} (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
            setTimeout(() => {
                if (this.connectionType) {
                    this.connect(type, this.gameId || undefined);
                }
            }, this.reconnectInterval);
        } else {
            console.error(`Max reconnection attempts reached for ${type}`);
        }
    }

    private disconnectGameSocket(): void {
        if (this.gameSocket) {
            console.log('Manually disconnecting game WebSocket');
            this.gameSocket.onopen = null;
            this.gameSocket.onmessage = null;
            this.gameSocket.onclose = null;
            this.gameSocket.onerror = null;
            this.gameSocket.close(1000, 'User initiated game disconnect');
            this.gameSocket = null;
        }
    }

    private disconnectTournamentSocket(): void {
        if (this.tournamentSocket) {
            console.log('Manually disconnecting tournament WebSocket');
            this.tournamentSocket.onopen = null;
            this.tournamentSocket.onmessage = null;
            this.tournamentSocket.onclose = null;
            this.tournamentSocket.onerror = null;
            this.tournamentSocket.close(1000, 'User initiated tournament disconnect');
            this.tournamentSocket = null;
        }
    }

    disconnect(): void {
        this.disconnectGameSocket();
        this.disconnectTournamentSocket();
        
        this.isConnecting = {};
        this.reconnectAttempts = 0;
        this.gameId = null;
        this.connectionType = null;
        this.messageHandlers = [];
    }

    isConnected(type?: ConnectionType): boolean {
        if (type === 'game') {
            return this.gameSocket !== null && this.gameSocket.readyState === WebSocket.OPEN;
        } else if (type === 'tournament_updates') {
            return this.tournamentSocket !== null && this.tournamentSocket.readyState === WebSocket.OPEN;
        }
        return (this.gameSocket !== null && this.gameSocket.readyState === WebSocket.OPEN) ||
               (this.tournamentSocket !== null && this.tournamentSocket.readyState === WebSocket.OPEN);
    }

    addMessageHandler(handler: WebSocketMessageHandler): void {
        this.messageHandlers.push(handler);
    }

    removeMessageHandler(handler: WebSocketMessageHandler): void {
        this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
    }

    sendMessage(message: any): void {
        // Determine which socket to use based on message type or content
        const socket = message.type === 'tournament_update' ? this.tournamentSocket : this.gameSocket;
        
        if (socket && socket.readyState === WebSocket.OPEN) {
            console.log('Sending WebSocket message:', message);
            socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not connected. Message not sent:', message);
            if (message.type === 'tournament_update') {
                this.scheduleReconnect('tournament_updates');
            } else {
                this.scheduleReconnect('game');
            }
        }
    }
}

export const webSocketService = new WebSocketService();