import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import CashGameCard from './CashGameCard';
import { fetchCashGames, CashGame } from '../services/cashGameService';

const GridContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;

const CashGameList: React.FC = () => {
  const [cashGames, setCashGames] = useState<CashGame[]>([]);

  useEffect(() => {
    const loadCashGames = async () => {
      try {
        const games = await fetchCashGames();
        setCashGames(games);
      } catch (error) {
        console.error("Error fetching cash games:", error);
      }
    };

    loadCashGames();
  }, []);

  return (
    <GridContainer>
      {cashGames.map((cashGame) => (
        <CashGameCard key={cashGame.id} cashGame={cashGame} />
      ))}
    </GridContainer>
  );
};

export default CashGameList;