import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const CanvasContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ResponsiveCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const ChessBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId: number;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const getScaleFactor = () => {
      const baseWidth = 1920;
      return Math.max(window.innerWidth / baseWidth, 0.5);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // Chess piece and currency symbols
    const chessSymbols = ['♔', '♕', '♖', '♗', '♘', '♙'];
    const currencySymbols = ['$', '₹'];
    const allSymbols = [...chessSymbols, ...currencySymbols];

    // Create floating symbols
    let floatingSymbols = Array(30).fill(null).map(() => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      symbol: allSymbols[Math.floor(Math.random() * allSymbols.length)],
      speed: 0.3 + Math.random() * 0.7,
      angle: Math.random() * Math.PI * 2,
      size: 24 + Math.random() * 24,
      isCurrency: currencySymbols.includes(allSymbols[Math.floor(Math.random() * allSymbols.length)])
    }));

    // Animation loop
    const animate = () => {
      if (!canvas || !ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Create gradient background
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
      gradient.addColorStop(0, '#e0f7fa');  // Light cyan
      gradient.addColorStop(1, '#b2ebf2');  // Slightly darker cyan
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const scaleFactor = getScaleFactor();

      // Draw chessboard pattern
      const tileSize = Math.max(canvas.width, canvas.height) / 10 * scaleFactor;
      const cols = Math.ceil(canvas.width / tileSize);
      const rows = Math.ceil(canvas.height / tileSize);

      for (let i = 0; i < cols; i++) {
        for (let j = 0; j < rows; j++) {
          if ((i + j) % 2 === 0) {
            ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';  // Light squares
          } else {
            ctx.fillStyle = 'rgba(176, 196, 222, 0.3)';  // Dark squares (light steel blue)
          }
          ctx.fillRect(i * tileSize, j * tileSize, tileSize, tileSize);
        }
      }

      // Draw and move symbols
      floatingSymbols.forEach(symbol => {
        ctx.font = `${symbol.size * scaleFactor}px Arial`;
        ctx.fillStyle = symbol.isCurrency ? 'rgba(75, 0, 130, 0.6)' : 'rgba(25, 25, 112, 0.6)';  // Indigo for currency, Midnight Blue for chess pieces
        ctx.fillText(symbol.symbol, symbol.x, symbol.y);
        symbol.x += Math.cos(symbol.angle) * symbol.speed * scaleFactor;
        symbol.y += Math.sin(symbol.angle) * symbol.speed * scaleFactor;

        // Wrap around screen edges
        symbol.x = (symbol.x + canvas.width) % canvas.width;
        symbol.y = (symbol.y + canvas.height) % canvas.height;
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <CanvasContainer>
      <ResponsiveCanvas ref={canvasRef} />
    </CanvasContainer>
  );
};

export default ChessBackground;