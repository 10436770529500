import { getAuth, User } from 'firebase/auth';

export const getIdToken = async (): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }
  return await user.getIdToken();
};

export const getUserId = async (): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }
  return user.uid;
};

export const getCurrentUser = (): User | null => {
  const auth = getAuth();
  return auth.currentUser;
};

// New function to get the auth token
export const getAuthToken = async (): Promise<string> => {
  return await getIdToken();
};