import React, { useState } from 'react';
import { AppBar, Toolbar, Button, styled, Box, useMediaQuery, useTheme, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';

const StyledAppBar = styled(AppBar)`
  background-color: rgba(26, 42, 80, 0.8);
`;

const StyledButton = styled(Button)`
  color: white;
  margin-left: 10px;
`;

const ChessSupremeButton = styled(Button)`
  color: white;
  font-weight: bold;
  font-size: 18px;
`;

interface MenuItem {
  label: string;
  action: () => void;
  icon?: React.ReactNode;
}

const LoginAppBar: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleNavigation = (path: string) => {
    navigate(path);
    setAnchorEl(null);
  };

  const handleDirectHomeNavigation = () => {
    navigate('/', { replace: true });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems: MenuItem[] = [
    { 
      label: 'Download App', 
      action: () => handleNavigation('/download'), 
      icon: <DownloadIcon />
    },
    { 
      label: 'How to Play', 
      action: () => handleNavigation('/how-to-play'),
      icon: <SportsEsportsIcon />
    },
    { 
      label: 'Terms & Conditions', 
      action: () => handleNavigation('/terms'),
      icon: <GavelIcon />
    },
    { 
      label: 'Privacy Policy', 
      action: () => handleNavigation('/privacy'),
      icon: <DescriptionIcon />
    },
    { 
      label: 'About Us', 
      action: () => handleNavigation('/about'),
      icon: <InfoIcon />
    },
    { 
      label: 'Contact Us', 
      action: () => handleNavigation('/contact'),
      icon: <EmailIcon />
    },
    { 
      label: 'Refund Policy', 
      action: () => handleNavigation('/refund-policy'),
      icon: <DescriptionIcon />
    },
  ];

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <ChessSupremeButton onClick={handleDirectHomeNavigation}>
          Chess Supreme
        </ChessSupremeButton>
        <Box sx={{ flexGrow: 1 }} />
        {isSmallScreen ? (
          <>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  mt: 1,
                  maxHeight: '80vh',
                  width: '200px',
                }
              }}
            >
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={item.action}
                  sx={{
                    padding: '10px 20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(26, 42, 80, 0.1)',
                    }
                  }}
                >
                  {item.icon}
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {menuItems.map((item, index) => (
              <StyledButton
                key={index}
                onClick={item.action}
                startIcon={item.icon}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                {item.label}
              </StyledButton>
            ))}
          </Box>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default LoginAppBar;